<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-text>
          <v-row dense no-gutters>
            <v-col cols="12" sm="2" md="2"> </v-col>

            <v-col cols="12" sm="1" md="1" align="center">Pumped volume</v-col>
            <v-col cols="12" sm="2" md="1" align="center"
              >Initial pressure</v-col
            >
            <v-col cols="12" sm="1" md="1" align="center">Final pressure</v-col>
            <v-col cols="12" sm="1" md="1" align="center">Pressure drop</v-col>
            <v-col cols="12" sm="1" md="1" align="center">Time</v-col>
            <v-col cols="12" sm="1" md="1"></v-col>
            <v-col cols="12" sm="4" md="4" align="center">Communication</v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" sm="12" md="12">
              <v-row
                class="rowh3"
                dense
                v-for="item in downs_list"
                :key="item.id"
              >
                <v-col cols="12" sm="2" md="2">
                  <span dense>{{ item.label }}</span></v-col
                >

                <v-col cols="12" sm="2" md="1">
                  <v-text-field
                    @change="get_failures()"
                    autocomplete="off"
                    type="number"
                    outlined
                    dense
                    hide-spin-buttons
                    v-model="item.p_volume"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="1">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    outlined
                    dense
                    hide-spin-buttons
                    v-model="item.i_pressure"
                    @input="changes"
                    @change="get_failures()"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="1">
                  <v-text-field
                    @change="get_failures()"
                    autocomplete="off"
                    type="number"
                    outlined
                    dense
                    hide-spin-buttons
                    v-model="item.f_pressure"
                    @input="changes"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    outlined
                    dense
                    v-model="item.drop_pressure"
                    hide-spin-buttons
                    :readonly="true"
                    @input="changes"
                    @change="get_failures()"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    outlined
                    dense
                    hide-spin-buttons
                    v-model="item.time"
                    @input="changes"
                    @change="get_failures()"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="1" md="1"></v-col>

                <v-col cols="12" sm="1" md="1" v-if="order > 1">
                  <v-text-field
                    @change="get_failures()"
                    autocomplete="off"
                    type="number"
                    outlined
                    dense
                    v-model="item.anl_a"
                    @input="changes"
                    hide-spin-buttons
                    :label="getlabel(1, item.csg_order)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" v-if="order > 2">
                  <v-text-field
                    @change="get_failures()"
                    autocomplete="off"
                    type="number"
                    outlined
                    dense
                    v-model="item.anl_b"
                    @input="changes"
                    hide-spin-buttons
                    :label="getlabel(2, item.csg_order)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" v-if="order > 3">
                  <v-text-field
                    @change="get_failures()"
                    autocomplete="off"
                    type="number"
                    outlined
                    dense
                    v-model="item.anl_c"
                    @input="changes"
                    hide-spin-buttons
                    :label="getlabel(3, item.csg_order)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" v-if="order > 4">
                  <v-text-field
                    @change="get_failures()"
                    autocomplete="off"
                    type="number"
                    outlined
                    dense
                    v-model="item.anl_d"
                    @input="changes"
                    hide-spin-buttons
                    :label="getlabel(4, item.csg_order)"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import allquery from "../graphql/WellTest/TestForm/allQuery.gql";
export default {
  components: {},
  props: {
    downs: Array,
    test_readonly: Boolean,
    failures_list: Array,
    editedItem: Object,
  },
  data() {
    return {
      order: 0,
      downs_list: [],
      all_failures_list: [],
      test_failures: [],
      barriers: [],
      well_failures: [],
    };
  },

  mounted() {
    this.getdata();
    this.downs_list = Object.assign([], this.downs);
    this.order = Math.max(...this.downs_list.map((item) => item.csg_order));
    this.well_failures = Object.assign([], this.failures_list);
    this.$emit("changes", this.downs_list, this.failures_list);
  },
  computed: {},
  watch: {},
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    async getdata() {
      let r = await this.$requette(allquery);
      if (r.ok) {
        this.all_failures_list = r.data.failures_list;
        this.barriers = r.data.wmf_list;
      }
    },
    communication_change(item) {
      if (item.anl_a || item.anl_b || item.anl_c || item.anl_d || item.anl_e)
        this.set_falure(item, 208, 2);
    },
    valuechanged_pressure(item) {
      if (item.i_pressure == 0) item.i_pressure = null;
      if (item.f_pressure == 0) item.f_pressure = null;
      if (item.i_pressure && item.f_pressure) {
        if (item.i_pressure != item.f_pressure) {
          if (
            Math.abs(item.i_pressure - item.f_pressure) >=
            (item.i_pressure * 3) / 100
          )
            this.set_falure(item, 212, 1);
          else if (
            Math.abs(item.i_pressure - item.f_pressure) <
            (item.i_pressure * 3) / 100
          )
            this.set_falure(item, 214, 1);
        }
      }
    },

    set_falure(item, failure, gid) {
      let fail = {
        id: null,
        state: 1,
        cmp_code: item.cmp_code,
        cmp_id: item.cmp_id,
        well_tubular_id: item.well_tubular_id,
        failure_id: failure,
        welltest_id: this.editedItem.id,
        g_id: gid,
        testtype: this.editedItem.testtype_id,
      };
      this.test_failures.push(
        this.$calc_single(this.barriers, this.all_failures_list, fail)
      );

      //this.$calc_single(this.barriers, this.failures_list, fail);
    },
    getlabel(index, order) {
      if (order == 1) {
        return (index + 9).toString(36).toUpperCase() + "-Annulus";
      }
      if (order == 2) {
        if (index == 1) return "Tube";
        if (index == 2) return "B- Annulus";
        if (index == 3) return "C- Annulus";
        if (index == 4) return "D- Annulus";
        if (index == 5) return "E- Annulus";
      }
      if (order == 3) {
        if (index == 1) return "Tube";
        if (index == 2) return "A- Annulus";
        if (index == 3) return "C- Annulus";
        if (index == 4) return "D- Annulus";
        if (index == 5) return "E- Annulus";
      }
      if (order == 4) {
        if (index == 1) return "Tube";
        if (index == 2) return "A- Annulus";
        if (index == 3) return "B- Annulus";
        if (index == 4) return "D- Annulus";
        if (index == 5) return "E- Annulus";
      }
      if (order == 5) {
        if (index == 1) return "Tube";
        if (index == 2) return "A- Annulus";
        if (index == 3) return "B- Annulus";
        if (index == 4) return "c- Annulus";
        if (index == 5) return "E- Annulus";
      }
      if (order == 6) {
        if (index == 1) return "Tube";
        if (index == 2) return "A- Annulus";
        if (index == 3) return "B- Annulus";
        if (index == 4) return "C- Annulus";
        if (index == 5) return "D- Annulus";
      }
    },
    get_failures() {
      this.downs_list.forEach((item) => {
        this.communication_change(item);
        this.valuechanged_pressure(item);
      });
      //calculate multiples
      let multiples = this.$get_multiples(
        this.well_failures,
        this.test_failures.filter((x) => x.cmp_code != "0" && x.failure_id > 0),
        this.barriers,
        this.all_failures_list,
        this.editedItem.id
      );

      // add multiples
      if (multiples)
        if (multiples.length > 0) {
          var max = multiples.reduce(function (prev, current) {
            if (+current.failure_taux < +prev.failure_taux) {
              return current;
            } else {
              return prev;
            }
          });
          this.test_failures.push(max);
        }
      //  if (multiples) multiples.forEach((m) => this.test_failures.push(m));
      this.$emit("changes", this.downs_list, this.test_failures);
    },
    changes() {
      this.get_failures();
      this.$emit("changes", this.downs_list, this.test_failures);
    },
  },
};
</script>
<style>
.rowh0 {
  width: 100% !important;
  height: 45px !important;
}
</style>
